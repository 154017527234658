@import url(https://fonts.googleapis.com/css?family=Rajdhani);
body{
  font-family: 'Rajdhani', sans-serif;
  height: 100vh;
  line-height: 1.6;
}
html,body,ul{
  margin: 0;
  padding: 0;
}
@media (min-width: 760px) {
.site-width{
    max-width: 980px;
    margin: 0 auto;
}
}
.clear-float{
  overflow: hidden;
}
a{
  text-decoration: none;
  color: #333;
}
.center {
  text-align: center;
}

@media (min-width: 760px) {
header[data-v-fbd0a4de] {
        position: relative;
        height: 150px;
}
}
@media (min-width: 760px) {
header h1[data-v-fbd0a4de]{
        position: absolute;
        margin: 0 auto;
        top: 50px;
        font-size: 40px;
}
}
header h1[data-v-fbd0a4de]{
    padding-left: 20px;
}
@media (min-width: 760px) {
header nav[data-v-fbd0a4de]{
        position: absolute;
        right: 0;
        bottom: 0;
}
}
nav ul[data-v-fbd0a4de]{
    list-style: none;
    display: table;
    width: 100vw;
    text-align: center;
    table-layout: fixed;
}
@media (min-width: 760px) {
nav ul[data-v-fbd0a4de]{
        display: inline;
}
}
nav ul li[data-v-fbd0a4de]{
    display: table-cell;
    vertical-align: middle;
    border-top: 1px solid #d9d9d9;
    border-bottom: 1px solid #d9d9d9;
}
nav ul li[data-v-fbd0a4de]:not(:last-child){
    border-right: 1px solid #d9d9d9;
}
@media (min-width: 760px) {
nav ul li[data-v-fbd0a4de]{
        margin-left: 40px;
        display: inline;
        border: none;
}
}
@media (min-width: 760px) {
nav ul li[data-v-fbd0a4de]:not(:last-child){
        border: none;
}
}
@media (min-width: 760px) {
nav ul li a[data-v-fbd0a4de]{
        font-size: 20px;
}
}
@media (min-width: 760px) {
nav ul li a[data-v-fbd0a4de]:hover{
        text-decoration: underline;
}
}
@media (min-width: 760px) {
nav ul li a.router-link-exact-active[data-v-fbd0a4de]:hover{
        text-decoration: none;
        cursor: default;
}
}
header nav ul li .router-link-exact-active[data-v-fbd0a4de]{
    opacity: 0.3;
}

#top{
  width: 100%;
  height: calc(100vh - 150px);
  position: relative;
  background: pink;
}
#top-img{
  width: 100%;
  height: 100%;
  position: absolute;
  opacity: 0.7;
}
#welcomMsg{
    margin: 0;
    font-size: 60px;
    width: 100%;
    color: #333;;
    position: absolute;
    text-align: center;
    top: 65%;
}
@media (min-width: 760px) {
#welcomMsg {
    font-size: 100px;
    top: 40%;
}
}
.top-pc{
  display: none;
}
@media (min-width: 760px) {
.top-pc {
    display: block;
}
}
@media (min-width: 760px) {
.top-sp {
    display: none;
}
}

.detail-table{
    padding-left: 50px;
    vertical-align: top;
}
.detail-table th, .detail-table td{
    padding-bottom: 15px;
}
.detail-table th{
  width: 80px;
  vertical-align: top;
  text-align: left;
}
.detail-table a:hover{
  text-decoration: underline;
}
#myicon{
  width: 300px;
  height: 300px;
  float: none;
  border-radius: 50%;
}
.myicon-img-wrap{
  text-align: center;
  margin-bottom: 20px;
}
@media (min-width: 760px) {
#myicon{
    float: left;
}
}

.page-title{
    font-weight: normal;
    text-align: center;
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 40px;
}
@media (min-width: 760px) {
.page-title{
    margin-top: 40px;
    margin-bottom: 40px;
}
}

.card-wrap{
    margin: 0 auto;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
}

.card-box{
  -webkit-box-shadow: 0 0 10px 0 rgba(0,0,0,.1);
          box-shadow: 0 0 10px 0 rgba(0,0,0,.1);
  width: 100%;
  height: 450px;;
  margin: 0px 15px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  border-radius: 0.25em;
}
@media (min-width: 760px) {
.card-box{
    width: 30%;
}
}
.card-box h2{
  text-align: center;
  font-size: 30px;
}
.skill-table{
  text-align: center;
  width: 100%;
}
.skill-table thead{
  font-size: 28px;
}
.skill-table thead th{
  padding-bottom: 10px;
  width: 50%;
}
.skill-table tbody th, .skill-table tbody td{
  padding-bottom: 15px;
}

.portfolio-work-img{
  width: 100%;
}
.portfolio-work-card{
  text-align: center;
}
.portfolio-work-card h2{
  font-weight: normal;
  margin-top: 0px;
  margin-bottom: 10px;
}
.portfolio-work-card p{
  margin-top: 0px;
  margin-bottom: 10px;
}

.contact-text[data-v-396f598e]{
  text-align: center;
  margin-bottom: 25px;
}
@media (min-width: 760px) {
form[data-v-396f598e]{
    width: 50%;
    margin: 0 auto;
}
}
form[data-v-396f598e]{
    padding-left: 5vw;
    padding-right: 5vw;
    margin: 0 auto;
}
input[data-v-396f598e],textarea[data-v-396f598e]{
    font-size: 18px;
    margin-bottom: 15px;
    margin-top: 5px;
}
input[type="text"][data-v-396f598e]{
    width: 100%;
    border: 1px solid #bbbbbb;
    padding: 10px;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
}
textarea[data-v-396f598e]{
    width: 100%;
    height: 130px;
    border: 1px solid #bbbbbb;
    padding: 10px;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
}
.submit[data-v-396f598e]{
    background: #999999;
    border: 1px solid #bbbbbb;
    padding: 10px 30px;
    color: white;
    float: right;
    margin-bottom: 15px;
    font-size: 18px;
}
.required[data-v-396f598e]{
    color: red;
    font-size: 12px;
    border: 1px solid red;
    padding: 0px 5px;
    display: inline-block;
    vertical-align: top;
    margin-top: 1px;
    margin-left: 5px;
}
.error-msg[data-v-396f598e]{
    font-size: 14px;
    margin-left: 10px;
    color: red;
}
.send-result-success[data-v-396f598e]{
    color: blue;
}
.send-result-error[data-v-396f598e]{
    color: red;
}

#unity-container {
  margin: 0 auto !important;
}
.licenseNotation p{
  text-align: center;
}
.licenseNotation a{
  color: #0048ae;
}
